import { Link } from 'gatsby';
import React from 'react';
import { slugify } from '../../utils/utils';
import * as S from './BlogPostCard.style';

// Image Width: 1920x1080

// TODO: Need to fix the nested anchor <a> links (main card and then
//      the card label for category).
//      One possible solution is to have the category link after the card,
//      But this is probably not good for semantics. One would have to make the
//      category anchor link hidden to readers?

function BlogPostCard({ slug, title, category, image, excerpt }) {
  return (
    <S.Link to={`/blog/${slugify(category)}/${slugify(slug)}`}>
      <S.Wrapper whileHover={{ scale: 1.05 }}>
        <S.ImgContainer>
          <S.Img fluid={image} alt={title} />
          <S.CategoryLink to={`/blog/${slugify(category)}`}>
            <S.Category>{category}</S.Category>
          </S.CategoryLink>
        </S.ImgContainer>
        <S.Link to={`/blog/${slugify(category)}/${slugify(slug)}`}>
          <S.Title>{title}</S.Title>
        </S.Link>
        <S.Excerpt>{excerpt}</S.Excerpt>
      </S.Wrapper>
    </S.Link>
  );
}

export default BlogPostCard;
