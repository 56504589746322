import React from 'react';
import { graphql, Link } from 'gatsby';
import * as S from './Blog.style';
import SEO from '../../components/SEO/SEO';
// import NavMain from '../../components/NavMain';
import LayoutBlog from '../../components/LayoutBlog';
import Image from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { slugify } from '../../utils/utils';
import BlogPostCard from '../../components/BlogPostCard/BlogPostCard';
import CategoryImage from '../../assets/images/category.jpg';

export const pageQuery = graphql`
  query MainBlogPage($ids: [String]) {
    categories: allMdx(filter: { id: { in: $ids } }) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          body
          fields {
            slug
          }
        }
      }
    }
    posts: allMdx {
      edges {
        node {
          excerpt(pruneLength: 160)
          frontmatter {
            title
            date(formatString: "MMM D, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

function Blog(props) {
  const { data } = props;
  const categories = data.categories.edges;
  return (
    <LayoutBlog>
      <SEO title="Blog" />
      <S.Wrapper>
        <S.Container>
          <h1>BLOG</h1>
          <div>
            <h2>Latest Blog Posts</h2>
          </div>
          <div>
            {categories.map(({ node }, index) => (
              <S.BlogSection key={index}>
                <Link to={'/blog' + slugify(node.fields.slug)}>
                  <h2>{node.frontmatter.title}</h2>
                </Link>
                <MDXRenderer>{node.body}</MDXRenderer>
                {/* <Image fluid={node.frontmatter.image.childImageSharp.fluid} /> */}
                {/* <S.CategoryImage src={CategoryImage} /> */}
                <S.Cards>
                  {data.posts.edges
                    .filter(({ node: post }) => {
                      return (
                        post.fields.slug.split('/').filter(Boolean).length ===
                          2 &&
                        post.fields.slug.split('/').filter(Boolean)[0] ===
                          node.fields.slug.split('/').filter(Boolean)[0]
                      );
                    })
                    .map(({ node: post }, index) => (
                      <div>
                        <BlogPostCard
                          key={index}
                          slug={post.fields.slug.split('/').filter(Boolean)[1]}
                          title={post.frontmatter.title}
                          date={post.frontmatter.date}
                          category={
                            post.fields.slug.split('/').filter(Boolean)[0]
                          }
                          excerpt={post.excerpt}
                          image={post.frontmatter.image.childImageSharp.fluid}
                        />
                      </div>
                    ))}
                </S.Cards>
              </S.BlogSection>
            ))}
          </div>
        </S.Container>
      </S.Wrapper>
    </LayoutBlog>
  );
}

export default Blog;
