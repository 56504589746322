import styled from 'styled-components';
import Image from 'gatsby-image';
import { motion } from 'framer-motion';
import { Link as GLink } from 'gatsby';

export const Link = styled(GLink)`
  text-decoration: none;
`;
export const CategoryLink = styled(GLink)`
  color: #fff;
  text-decoration: none;
`;

export const Wrapper = styled(motion.li)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ImgContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

export const Img = styled(Image)`
  border-radius: 4px;
`;

export const Category = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Trump Gothic East', sans-serif;
  letter-spacing: 1.2px;
  line-height: 1;
  background: var(--color-primary);
  padding: 5px 6px;
  width: auto;
  border-radius: 2px;
  position: absolute;
  /* bottom: 15px; */
  top: 15px;
  left: 1px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    font-size: 12px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const DateDisplay = styled.div``;

export const CategoryContainer = styled.div``;

export const Excerpt = styled.div`
  color: var(--color-text);

  font-size: 14px;
`;
