import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 400px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 0px 20px 20px 20px;
  border-radius: 2px;
  @media screen and (min-width: 480px) {
    padding: 0px 32px 32px 32px;
  }
`;

export const Cards = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto;
  padding: 0;
  /* max-width: 1160px; */
  grid-gap: 25px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.l + 'px'}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CategoryImage = styled.img`
  border-radius: 6px;
`;

export const BlogSection = styled.section`
  h2 {
    margin-bottom: 3px;
  }
  p {
    margin-bottom: 20px;
  }
  margin-bottom: 40px;
`;
