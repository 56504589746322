import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import './LayoutBlog.scss';
import { ThemeProvider } from 'styled-components';
import { THEME } from '../../constants';
import NavMain from '../NavMain/NavMain';
import Footer from '../Footer/Footer';
import * as S from './LayoutBlog.style';
import './key.css';

const headerLinks = [
  { text: 'Home', to: '/' },
  { text: 'Blog', to: '/blog' },
];

function LayoutBlog({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleBlogQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <ThemeProvider theme={THEME}>
        <NavMain links={headerLinks} page="blog" />
        <S.Wrapper>
          <S.Main>{children}</S.Main>
          <Footer />
        </S.Wrapper>
      </ThemeProvider>
    </>
  );
}

LayoutBlog.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutBlog;
